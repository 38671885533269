import gql from 'graphql-tag';

export const helpfulVotePost = gql`
  mutation HelpfulVotePost($postExternalId: String!, $vote: HelpfulVotePostEnum!) {
    helpfulVotePost(input: { attributes: { postExternalId: $postExternalId, vote: $vote } }) {
      userErrors {
        message
      }
    }
  }
`;
