import ContentLoader from 'react-content-loader';

export const AppRatingLoader = ({
  bottomOfPage = false,
}: {
  bottomOfPage?: boolean;
}): JSX.Element => {
  return (
    <ContentLoader
      uniqueKey="fishbrain-apprating-key"
      className="flex items-center justify-center"
      width={120}
      height={bottomOfPage ? 48 : 24}
    >
      <rect x="5" y="5" rx="5" ry="5" width="120" height={bottomOfPage ? 48 : 24} />
    </ContentLoader>
  );
};
