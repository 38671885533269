import ContentLoader from 'react-content-loader';

export const AppRatingImageLoader = ({
  bottomOfPage = false,
}: {
  bottomOfPage?: boolean;
}): JSX.Element => {
  return (
    <ContentLoader
      uniqueKey="fishbrain-appratingimage-key"
      className={`mt-4 ${bottomOfPage ? 'sm:mt-0' : ''}`}
      style={{ width: '95px', height: '59px' }}
    >
      <rect x="0" y="0" rx="5" ry="5" width="95" height="59" />
    </ContentLoader>
  );
};
