import ContentLoader from 'react-content-loader';

import { RATING_ICON_WIDTH, RATING_ICON_WIDTH_LARGE } from '../constants/constants';

interface IProps {
  numberOfReviews?: number;
  isLarge?: boolean;
}

export const RatingLabelLoader = ({ numberOfReviews, isLarge = false }: IProps): JSX.Element => {
  return (
    <ContentLoader
      uniqueKey="ratinglabel-loader-key"
      className="flex items-center justify-center"
      style={{
        width: numberOfReviews ? `120px` : `20px`,
        height: isLarge ? `${RATING_ICON_WIDTH_LARGE}px` : `${RATING_ICON_WIDTH}px`,
      }}
    >
      <rect
        x="2"
        y="0"
        rx="5"
        ry="5"
        width={numberOfReviews ? `118px` : `18px`}
        height={isLarge ? `${RATING_ICON_WIDTH_LARGE}px` : `${RATING_ICON_WIDTH}px`}
      />
    </ContentLoader>
  );
};
