/* eslint-disable no-magic-numbers */
import React from 'react';

import { QRCodeSVG } from 'qrcode.react';
import ContentLoader from 'react-content-loader';
import { twMerge } from 'tailwind-merge';

import { useAnalytics } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { useClientHasRendered } from '../../helpers/hooks';
import { useIsMobileDevice } from '../../helpers/hooks/useIsMobileDevice';
import { AppDownloadUrlSource, getAppDownloadUrl } from '../../helpers/url/getAppDownloadUrl';

import blackFish from './assets/Black_mono_fish.png';

/*  
By default the QR code will be hidden on mobile devices, but you can override this via the
showOnMobileDevice prop. To avoid CLS loading spinner will be shown until the device detection 
on the client has been completed.

NB: Make sure you test that the QR code will scan if you change the default size,
as the picture removes some of the readable area and if you lose too much the 
code doesn't work 
*/
export const QRCode = ({
  link,
  size = 125,
  level = 'M',
  bgColor = '#FFFFFF',
  fgColor = '#000000',
  className,
  showOnMobileDevice = false,
}: {
  link?: string;
  size?: number;
  level?: 'L' | 'M' | 'Q' | 'H';
  bgColor?: '#FFFFFF' | '#F5F6F7';
  fgColor?: string;
  className?: string;
  showOnMobileDevice?: boolean;
}): JSX.Element => {
  const clientHasRendered = useClientHasRendered();
  const isMobileDevice = useIsMobileDevice();
  const { currentPage } = useAnalytics();

  const defaultAppDownloadLink = getAppDownloadUrl(AppDownloadUrlSource.QrCode, currentPage);

  if (!showOnMobileDevice && isMobileDevice) {
    return <></>;
  }

  return (
    <div className={twMerge(`${showOnMobileDevice ? 'flex' : 'hidden sm:flex'}`, className)}>
      {clientHasRendered ? (
        <QRCodeSVG
          value={link || defaultAppDownloadLink}
          size={size}
          level={level}
          bgColor={bgColor}
          fgColor={fgColor}
          imageSettings={{ src: blackFish.src, height: 29, width: 29, excavate: true }}
          data-testid="DownloadAppQRCode"
        />
      ) : (
        <ContentLoader
          uniqueKey="qrcode-loader-key"
          className="flex items-center justify-center"
          style={{
            minWidth: `${size}px`,
            minHeight: `${size}px`,
            height: `${size}px`,
            width: `${size}px`,
          }}
        >
          <rect x="0" y="0" rx="5" ry="5" width="220" height={size} />
        </ContentLoader>
      )}
    </div>
  );
};
