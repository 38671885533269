import { twMerge } from 'tailwind-merge';

const variants = {
  default: 'xl:container xl:mx-auto',
  thin: 'max-w-screen-lg mx-auto',
  extraThin: 'max-w-screen-sm mx-auto',
} as const;

export const DEFAULT_CONTAINER_PADDING = 'px-6 sm:px-11 md:px-14 lg:px-16 xl:container xl:mx-auto';

export const Container = ({
  className = '',
  variant = 'default',
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { variant?: keyof typeof variants }): JSX.Element => (
  <div
    className={twMerge(`${variants[variant]} px-6 sm:px-11 md:px-14 lg:px-16`, className)}
    {...rest}
  />
);
